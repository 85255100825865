@use "../../foundation/import" as *

/* -------------------------------
 *  button components
 * ---------------------------- */
.u-button-default
  padding: var(--space-XS) var(--space-S)
  line-height: 1
  border-radius: 99em
  &.u-button-default--negative
    background: var(--ui-color__negative)  
    color: var(--color-white)

.u-button-outline    
  padding: var(--space-XS) var(--space-S)
  line-height: 1
  border-radius: 99em
  border: 2px solid
  &.u-button-outline--primary
    border-color: var(--primary-color__4)
    color: var(--primary-color__4)