@use "../foundation/import" as *

/* -------------------------------
 *  footer
 * ---------------------------- */
.l-footer
  grid-area: footer
  .__block-depth-1
    padding: var(--space-M) 0 var(--space-R) 0
    border-top: 1px solid var(--base-color__3)
    .__copyright
      font-size: var(--font-XS)
      color: var(--base-color__5)