@use "../foundation/import" as *

/* -------------------------------
 *  main
 * ---------------------------- */
.l-main
  grid-area: main

/* -------------------------------
 *  aside
 * ---------------------------- */
.l-aside
  margin: var(--space-XL) 0