<template>
  <transition name="fade" mode="out-in">
    <div v-if="store.getters.getIsShowDetails" class="c-color-detail-area">
      <pallet-detail-background :props-color="store.getters.getCurrentColor" :props-status="store.getters.getIsShowDetails" />
      <pallet-detail-dialog :props-color="store.getters.getCurrentColor" :props-status="store.getters.getIsShowDetails" />
    </div>
  </transition>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import { key } from '../store';
  import PalletDetailBackground from './PalletDetailBackground.vue';
  import PalletDetailDialog from './PalletDetailDialog.vue';
  export default defineComponent({
    components: {
      PalletDetailBackground,
      PalletDetailDialog
    },
    setup() {
      const store = useStore(key);
      return {
        store
      }
    },
  });
</script>

<style scoped>
  .fade-enter-active {
    transition: opacity 0.2s ease 0.1s;
  }
  .fade-leave-active {
    transition: opacity 0.2s ease 0.1s;
  }
  .fade-enter-from {
    opacity: 0;
  }
  .fade-enter-to {
    opacity: 1;
  }
  .fade-leave-from {
    opacity: 1;
  }
  .fade-leave-to {
    opacity: 0;
  }
</style>