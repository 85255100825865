<template>
  <transition-group name="fade" tag="div" mode="out-in" class="p-color-pallet-grid-container">
    <pallet-item v-for="(color, index) in data.currentShowItems" :key="color.id" :props-color="color" />
  </transition-group>
  <div v-if="data.total > data.currentShowItems.length" class="u-flex-center-center u-mgt-064">
    <button class="__reload u-button-outline u-button-outline--primary u-upper-case u-bold" type="button" @click="load()">
      <span class="__text u-upper-case u-bold">more</span>
    </button>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, watchEffect } from 'vue';
  import { useStore } from 'vuex';
  import { key } from '../store';
  import { useRoute } from 'vue-router';
  import PalletItem from './PalletItem.vue';

  interface colorItemInterface {
    id: number | null,
    category: string | null,
    tags: string[] | null,
    code: string[] | null,
  }

  interface dataInterface {
    currentShowItems: colorItemInterface[],
    total: number,
    loadParCount: number,
    offset: number,
    currentPage: string | string[]
  }

  export default defineComponent({
    components: {
      PalletItem
    },
    props: {
      propsColors: {
        type: () => [],
        default: []
      }
    },
    setup(props) {
      const store = useStore(key);
      const route = useRoute();
      const data:dataInterface = reactive({
        currentShowItems: [],
        total: 0,
        loadParCount: 12,
        offset: 0,
        currentPage: ''
      });
      const init = () => {
        data.offset = 0;
        data.currentShowItems = [];
        setTimeout(() => {
          data.currentShowItems = props.propsColors.slice(0, data.loadParCount);
          data.total = props.propsColors.length;
        }, 100)
      };
      const load = () => {
        data.offset += data.loadParCount;
        data.currentShowItems = data.currentShowItems.concat(props.propsColors.slice(data.offset, data.offset + data.loadParCount));
      };
      watchEffect(() => {
        data.currentPage = route.params.term;
        init();
      });
      return {
        store,
        data,
        route,
        watchEffect,
        init,
        load
      }
    },
  });
</script>

<style scoped>
  .fade-enter-active {
    transition: opacity 0.4s ease 0.1s, transform 0.4s ease 0.1s;
  }
  .fade-leave-active {
    transition: opacity 0.4s ease 0.1s, transform 0.4s ease 0.1s;
  }
  .fade-enter-from {
    opacity: 0;
    transform: translate(0, 32px);
  }
  .fade-enter-to {
    opacity: 1;
    transform: translate(0, 0);
  }
  .fade-leave-from {
    opacity: 1;
    transform: translate(0, 0);
  }
  .fade-leave-to {
    opacity: 0;
    transform: translate(0, 32px);
  }
</style>