<template>
  <transition-group name="slide" mode="out-in" tag="div" class="__background u-flex-center-stretch" @click="closeDetails()">
    <span 
      v-for="(hex, index) in propsColor.code" 
      :key="hex" 
      class="__panel u-flex-shrink-1"
      :style="{background: hex, transitionDelay: `calc(${(index + 1) * 0.3}s + 0.1s)`}"
    >
    </span>
  </transition-group>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import { key } from '../store';
  export default defineComponent({
    props: {
      propsColor: () => {},
      propsStatus: Boolean
    },
    setup(props) {
      const store = useStore(key);
      const closeDetails = (): void => {
        store.dispatch('toggleShowDetails', false)
        setTimeout((): void => {
          store.dispatch('updateCurrentColor', {
            id: null,
            category: null,
            tags: null,
            code: null
          })
        }, 100);
      };
      return {
        store,
        closeDetails
      }
    },
  });
</script>

<style scoped>
  .slide-enter-active {
    transition: transform 0.3s ease 0.1s;
  }
  .slide-leave-active {
    transition: transform 0.1s ease 0.1s;
  }
  .slide-enter-from {
    transform: translate(0, -100%);
  }
  .slide-enter-to {
    transform: translate(0, 0);
  }
  .slide-leave-from {
    transform: translate(0, 0);
  }
  .slide-leave-to {
    transform: translate(0, 100%);
  }
</style>