@use "../../foundation/import" as *

/* -------------------------------
 *  navigation elements
 * ---------------------------- */
.c-global-navi
  .__list
    display: flex
    justify-content: flex-end
    align-items: center
    +media-query(sm)
      flex-direction: column
      justify-content: center
      align-items: flex-end
    .__item
      .__button
        margin: 0 var(--space-XS)
        +media-query(sm)
          margin: var(--space-XS) 0 
      &:first-child,
      &:last-child
        .__button
          margin: 0
        