@use "variable" as *
@use "mixin" as *

/* -------------------------------
 *  extend styles
 * ---------------------------- */
%button-block-default-style
  display: block
  width: 100%
  +hover-style()
    cursor: pointer
%button-inline-default-style
  display: inline-block
  width: auto
  +hover-style()
    cursor: pointer    
%text-input-block-default-style
  display: block
  width: 100%
  border: 1px solid
%text-input-inline-default-style
  display: inline-block
  width: auto
  border: 1px solid  