@use "../foundation/import" as *

/* -------------------------------
 *  html body
 * ---------------------------- */
html
  width: 100%
  height: 100%
  font-size: 62.5%
  body
    width: inherit
    height: inherit
    min-height: 100vh
    font-family: var(--font-jost)
    font-size: var(--default-font-size)
    line-height: var(--default-line-height)
    color: var(--default-color)
    font-feature-settings: "palt"
    background: var(--base-color__1)
  +media-query(sm)
    body
      font-size: var(--font-S)

/* -------------------------------
 *  base layout
 * ---------------------------- */

/* -- layout ------------------ */
.l-base
  width: inherit
  height: auto !important
  min-height: 100vh
  position: relative
  // overflow: hidden
  display: grid
  grid-template: "header" auto "main" 1fr "footer" auto / 100%