@use "../foundation/import" as *

/* -------------------------------
 *  header
 * ---------------------------- */
.l-header
  grid-area: header
  .__block-depth-1
    padding: var(--space-M) 0
    border-bottom: 1px solid var(--base-color__3)
    .__block-depth-2
      .__button
        width: 36px
        height: 36px
        border: 1px solid var(--base-color__3)
        background: var(--base-color__1)
        border-radius: 100%  
        svg
          transition: transition(fill) 
          fill: var(--base-color__4)
        +hover-style()
          svg
            fill: var(--primary-color__4)
      &.__block-depth-2--1st,
      &.__block-depth-2--3rd
        width: 132px
        +media-query(sm)
          width: auto
      &.__block-depth-2--2nd
        padding: 0 var(--space-R)