/* -------------------------------
 *  Yu Gothic
 * ---------------------------- */
@font-face
  font-family: "Yu Gothic"
  src: local("Yu Gothic Medium")
  font-weight: 100
@font-face
  font-family: "Yu Gothic"
  src: local("Yu Gothic Medium")
  font-weight: 200
@font-face
  font-family: "Yu Gothic"
  src: local("Yu Gothic Medium")
  font-weight: 300
@font-face
  font-family: "Yu Gothic"
  src: local("Yu Gothic Medium")
  font-weight: 400
@font-face
  font-family: "Yu Gothic"
  src: local("Yu Gothic Bold")
  font-weight: bold

/* -------------------------------
 *  Helvetica Neue
 * ---------------------------- */
@font-face
  font-family: "Helvetica Neue"
  src: local("Helvetica Neue Regular")
  font-weight: 100
@font-face
  font-family: "Helvetica Neue"
  src: local("Helvetica Neue Regular")
  font-weight: 200