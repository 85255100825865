@use "../../foundation/import" as *

/* -------------------------------
 *  parts styles
 * ---------------------------- */
.c-tags-scroller
  background: var(--base-color__6)
  overflow: hidden
  .__tags-group
    display: inline-flex
    +animation-infinity-loop-x(2, 90s)
    +hover-style()
      animation-play-state: paused
    .__tags
      height: 24px
      white-space: nowrap
      .__tag
        color: var(--primary-color__5)
        margin: 0 var(--space-XL) 0 0
        font-size: var(--font-R)
        letter-spacing: 0.1rem
        transition: transition(color), transition(text-shadow)
        +hover-style()
          cursor: pointer
          color: var(--primary-color__2)
          text-shadow: 0 0 8px var(--primary-color__4)

.c-tags-selector
  position: relative
  height: 36px
  .__button
    position: relative
    z-index: 2
  .__select
    position: absolute
    left: 0
    top: 0
    width: 0
    z-index: 1
    padding: 0 0 0 36px
    height: 100%
    transition: transition(width)
    &.__select--shown
      width: 136px

.c-color-detail-area
  position: fixed
  left: 0
  top: 0          
  width: 100%
  z-index: var(--layer-order__dialog)
  .__background
    width: 100%
    height: 100vh
    .__panel
      width: 100%
      height: 100%
      display: block
  .__dialog
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    width: clamp(300px, 33.3333%, 480px)
    .__box
      background: var(--color-white)
      border-radius: var(--default-border-radius)
      box-shadow: var(--default-box-shadow)
      padding: var(--space-R)
      .__scales
        width: 100%
        .__scale
          width: 100%
          height: 140px
          +media-query(sm)
            height: 100px
          &:first-child
            border-radius: 8px 0 0 8px
          &:last-child
            border-radius: 0 8px 8px 0
      .__number
        font-size: var(--font-XL)
        font-weight: normal
        line-height: 1
        margin: var(--space-R) 0 0 0
        &::before
          content: "."
      .__tags
        .__tag
          display: inline-block
          margin: var(--space-S) var(--space-S) 0 0 
          line-height: 1
          padding: var(--space-XXXS) var(--space-XS)
          background: var(--base-color__2)
          border-radius: 99em
          font-size: var(--font-S)
          letter-spacing: 0.1rem
          color: var(--base-color__5)
          border: 1px solid var(--base-color__2)
      .__rectangles
        margin: var(--space-R) 0
        .__rectangle
          height: 30px
          padding: 0 var(--space-XXS)
          overflow: hidden
          .__group
            transform: translate(0, 0)
            transition: transition(transform)
            .__code,
            .__info
              line-height: 30px
              display: inline-block
          +hover-style()
            cursor: pointer
            .__group
              transform: translate(0, -30px)