<template>
  <div class="p-error-404 u-flex-center-center u-flex-vertical">
    <h2>404 Error</h2>
    <p>Page Not Found</p>
  </div>
</template>

<script lang="ts">  
  import { defineComponent } from 'vue';

  export default defineComponent({
    setup() {
      return {
      }
    }
  });
</script>

<style scoped>
  h2 {
    font-size: 3.6rem;
  }
  p {
    font-size: 2.4rem;
  }
</style>