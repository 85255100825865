@use "../../foundation/import" as *

/* -------------------------------
 *  common
 * ---------------------------- */
.p-color-pallet-grid-container    
  display: grid
  grid-template-rows: auto
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 240px), 1fr))
  gap: var(--space-R)
.p-color-pallet-card
  height: 100%
  border-radius: var(--default-border-radius)
  box-shadow: var(--default-box-shadow)
  overflow: hidden
  position: relative
  transform: translate(0, 0)
  transition: transition(transform), transition(box-shadow)
  .p-color-pallet-scale-group
    height: 100%
    .p-color-pallet-scale-item
      width: 100%
      height: 100%
      .__element
        width: 100%
        height: 200px
        display: block
  .p-color-pallet-label
    position: absolute
    left: 0
    top: 50%
    width: 100%
    background: var(--color-white)
    display: flex
    justify-content: flex-end
    align-items: flex-end
    padding: var(--space-S)
    height: 50%
    transform: translate(0, 0)
    transform-origin: center bottom
    transition: transform 0.4s ease 0.4s, transition(box-shadow)
    box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.2)
    .__number
      font-size: var(--font-XL)
      display: inline-block
      line-height: 1
      opacity: 1
      transition: opacity 0.2s ease 0.1s, transform 0.2s ease 0.1s
      transform: translate(0, 0)
      &::before
        content: "."
      &::after
        content: attr(data-code)
  .p-color-pallet-sign
    position: absolute
    left: 0
    bottom: 0
    width: 100%
    height: 40px
    text-align: center
    overflow: hidden
    display: flex
    justify-content: center
    align-items: center
    transform: translate(0, 40px)
    transition: transform 0.4s ease 0.6s
    .__text
      font-size: var(--font-S)
  +hover-style()
    cursor: pointer
    box-shadow: var(--hover-box-shadow)
    transform: translate(0, 4px)
    .p-color-pallet-label
      transform: translate(0, calc(100% - 40px))
      .__number
        opacity: 0
        transform: translate(8px, 0)
    .p-color-pallet-sign
      transform: translate(0, 0)

/* -------------------------------
 *  01_home
 * ---------------------------- */
.p-front-page
  .__reload  
    min-width: 200px