@use "variable" as *

/* -------------------------------
 *  media query
 * ---------------------------- */
=media-query($breakpoint-key, $rule: max-width)
  @if $rule == max-width
    @media (max-width: #{map-get($breakpoint-list, $breakpoint-key)}px)
      @content
  @else if $rule == min-width
    @media (min-width: calc(#{map-get($breakpoint-list, $breakpoint-key)}px + 1px))
      @content

/* -------------------------------
 *  hover & focus
 * ---------------------------- */
=hover-style($disabled: false)
  @media (hover: hover) and (pointer: fine)
    &:hover
      @content
  @if $disabled
    &:hover
      @content
=focus-style
  &:focus
    @content

/* -------------------------------
 *  screen reader
 * ---------------------------- */
=screen-reader-style
  position: absolute
  clip: rect(1px, 1px, 1px, 1px)
  left: -9999px
  top: -9999px

/* -------------------------------
 *  clearfix
 * ---------------------------- */
=clearfix
  &::after
    content: ""
    clear: both
    display: block

/* -------------------------------
 *  hack
 * ---------------------------- */
=hack-ie11
  @media all and (-ms-high-contrast: none)
    *::-ms-backdrop, body
      @content
=hack-edge
  @supports (-ms-ime-align: auto)
    @content
=hack-firefox
  @-moz-document url-prefix()
    @content
=hack-chrome
  @media screen and (-webkit-min-device-pixel-ratio: 0)
    @content
=hack-safari($selecter: "*.hack-safari")
  _::-webkit-full-page-media, _:future, :root #{$selecter}
    @content
