/* -------------------------------
 *  easing transition
 * ---------------------------- */
$easing-function-list: (linear: linear, ease: ease, ease-in: ease-in, ease-out: ease-out, ease-inout: ease-in-out, ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715), ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1), ease-inout-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95), ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53), ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94), ease-inout-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955), ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19), ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1), ease-inout-cubic: cubic-bezier(0.645, 0.045, 0.355, 1), ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22), ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1), ease-inout-quart: cubic-bezier(0.77, 0, 0.175, 1), ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06), ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1), ease-inout-quint: cubic-bezier(0.86, 0, 0.07, 1), ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035), ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1), ease-inout-expo: cubic-bezier(1, 0, 0, 1), ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335), ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1), ease-inout-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86), ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045), ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275), ease-inout-back: cubic-bezier(0.68, -0.55, 0.265, 1.55)) !default
@function transition($transition-property: all, $transition-duration: 0.5s, $easing-function-key: ease, $transition-delay: 0s)
  @return $transition-property $transition-duration #{map-get($easing-function-list, $easing-function-key)} $transition-delay

/* -------------------------------
 *  keyframes & mixin
 * ---------------------------- */  
=animation-infinity-loop-x($repeat-count: 2, $duration: 60s)
  @keyframes keyframes-infinity-loop-x
    0%
      transform: translateX(0%)
    100%
      transform: translateX(calc(100 / $repeat-count) * -1%)
  animation: keyframes-infinity-loop-x $duration linear 0.1s infinite normal both running