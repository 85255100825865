<template>
  <div class="__dialog">  
    <transition name="fade" mode="out-in">
      <div v-if="data.isShowDialog" class="__box">
        <div class="__scales u-flex-center-stretch">
          <span v-for="hex in propsColor.code" :key="hex" class="__scale u-flex-shrink-1" :style="{background: hex}"></span>
        </div>
        <h2 class="__number">{{ propsColor.id }}</h2>
        <ul class="__tags u-flex-start-start u-flex-wrap">
          <li v-for="tag in propsColor.tags" :key="tag" class="__tag u-capital-case u-bold">{{ tag }}</li>
        </ul>
        <ul class="__rectangles">
          <pallet-detail-code v-for="hex in propsColor.code" :key="hex" :props-code="hex" />
        </ul>
        <div class="u-flex-center-center">
          <button class="u-button-default u-button-default--negative" type="button" @click="closeDetails()">
            <span class="__text u-capital-case u-bold">close</span>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, reactive } from 'vue';
  import { useStore } from 'vuex';
  import { key } from '../store';
  import PalletDetailCode from './PalletDetailCode.vue';
  export default defineComponent({
    components: {
      PalletDetailCode
    },
    props: {
      propsColor: () => {},
      propsStatus: Boolean
    },
    setup(props) {
      const store = useStore(key);
      const data = reactive({
        isShowDialog: false
      })
      const closeDetails = (): void => {
        store.dispatch('toggleShowDetails', false)
        setTimeout((): void => {
          store.dispatch('updateCurrentColor', {
            id: null,
            category: null,
            tags: null,
            code: null
          })
        }, 100);
      };
      onMounted(() => {
        setTimeout((): void => {
          data.isShowDialog = props.propsStatus;
        }, 400);
      }); 
      return {
        store,
        data,
        closeDetails
      }
    },
  });
</script>

<style scoped>
  .fade-enter-active {
    transition: opacity 0.4s ease 1.6s, transform 0.4s ease 1.6s;
  }
  .fade-leave-active {
    transition: opacity 0.2s ease 0.1s, transform 0.4s ease 1.6s;;
  }
  .fade-enter-from {
    opacity: 0;
    transform: translate(0, 32px);
  }
  .fade-enter-to {
    opacity: 1;
    transform: translate(0, 0);
  }
  .fade-leave-from {
    opacity: 1;
    transform: translate(0, 0);
  }
  .fade-leave-to {
    opacity: 0;
    transform: translate(0, 32px);
  }
</style>