@use "../../foundation/import" as *

/* -------------------------------
 *  form components
 * ---------------------------- */
.u-select-wrap 
  background: var(--color-white)
  box-shadow: var(--default-box-shadow-inner)
  border-radius: 99em
  select
    height: 100%
    width: 100%
    padding: var(--space-XXXS) var(--space-XS)
    font-size: var(--font-S)