/* -------------------------------
 *  global layout reset
 * ---------------------------- */
*
  padding: 0
  margin: 0
  border: 0
  outline: 0
  font-family: inherit
  font-size: inherit
  line-height: inherit
  box-sizing: border-box
  background-repeat: no-repeat
*::before,
*::after
  background-repeat: no-repeat
  box-sizing: inherit
*:before,
*:after
  text-decoration: inherit
  vertical-align: inherit
  box-sizing: inherit
html
  scroll-behavior: smooth
body
  -webkit-text-size-adjust: none
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-rendering: optimizeLegibility
body a
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
@media all and (-ms-high-contrast: none)
  body
    font-family: Verdana, Meiryo, sans-serif
@supports (-webkit-touch-callout: none)
  body
    min-height: -webkit-fill-available    

/* -------------------------------
 *  element style reset
 * ---------------------------- */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary
  display: block
summary
  display: list-item
small
  font-size: 80%
[hidden],
template
  display: none
abbr[title]
  border-bottom: 1px dotted
  text-decoration: none
a
  background-color: transparent
  -webkit-text-decoration-skip: objects
a:active,
a:hover
  outline-width: 0
a:not([class])
  text-decoration-skip-ink: auto
code,
kbd,
pre,
samp
  font-family: monospace, monospace
audio:not([controls])
  display: none
  height: 0
hr
  overflow: visible
b,
strong
  font-weight: bolder
dfn
  font-style: italic
sub,
sup
  font-size: 75%
  line-height: 0
  position: relative
  vertical-align: baseline
sub
  bottom: -0.25em
sup
  top: -0.5em
table
  border-collapse: collapse
  border-spacing: 0
ol,
ul
  list-style-type: none
img,
video
  max-width: 100%
img
  border-style: none
  vertical-align: bottom
blockquote,
q
  quotes: none
blockquote:after,
blockquote:before,
q:after,
q:before
  content: ""
progress
  vertical-align: baseline
svg:not(:root)
  overflow: hidden
audio,
canvas,
progress,
video
  display: inline-block

/* -------------------------------
 *  form style reset
 * ---------------------------- */
*
  -webkit-appearance: none
input
  border-radius: 0
button
  cursor: pointer
textarea
  overflow: auto
  resize: vertical
optgroup
  font: inherit
button
  overflow: visible
button,
input,
select,
textarea
  background-color: transparent
  border-style: none
  color: inherit
  font: inherit
button,
select
  text-transform: none
legend
  border: 0
  color: inherit
  display: table
  max-width: 100%
  white-space: normal
[hidden]
  display: none !important
[disabled]
  cursor: not-allowed
[type="number"]
  width: auto
[type="search"]
  outline-offset: -2px
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration
  -webkit-appearance: none
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner
  border-style: 0
  padding: 0
button:-moz-focusring,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner
  outline: 1px dotted ButtonText
\:focus:not(:focus-visible)
  outline: none
select::-ms-expand
  display: none
select::-ms-value
  color: currentColor
input:-webkit-autofill
  -webkit-box-shadow: 0 0 0px 1000px #fff inset
\:placeholder-shown
  color: #ccc
\::-webkit-file-upload-button
  -webkit-appearance: button
  font: inherit
\::-moz-selection
  background-color: #b3d4fc
  color: #000
  text-shadow: none
\::selection
  background-color: #b3d4fc
  color: #000
  text-shadow: none

/* -------------------------------
 *  accessibility
 * ---------------------------- */
@media screen
  [hidden~="screen"]
    display: inherit
  [hidden~="screen"]:not(:active):not(:focus):not(:target)
    position: absolute !important
    clip: rect(0 0 0 0) !important
[aria-busy="true"]
  cursor: progress
[aria-controls]
  cursor: pointer
[aria-disabled]
  cursor: not-allowed

/* -------------------------------
 *  animation reset
 * ---------------------------- */
@media (prefers-reduced-motion: reduce)
  *
    animation-duration: 0.01ms !important
    animation-iteration-count: 1 !important
    transition-duration: 0.01ms !important
    scroll-behavior: auto !important

/* -------------------------------
 *  printing mode
 * ---------------------------- */
@media print
  html body
    zoom: 65%